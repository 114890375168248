.lastHero {
  background: linear-gradient(rgba(29, 53, 87, 0.83), rgba(29, 53, 87, 0.83)),
    url(../assets/offer-bg.webp);
  height: 25vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lastHeroContent {
  width: 45%;
}

.lastHeroContent h1 {
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 40px;
}

.lastHeroContent h2 {
  font-size: 18px;
  font-weight: 200;
  line-height: 22px;
  width: 80%;
  margin: 0 auto;
}

.lastHeroContent h2 span {
  font-weight: 600;
  display: inline-block;
  margin-top: 20px;
}

.lastHeroContent button {
  margin: 10px;
  background-color: #c64756;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 4px;
  font-size: 125%;
  font-weight: bold;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  margin-top: 30px;
}

.finalStep>div {
  width: 40%;
  margin: auto;
  padding: 40px 0;
}

.stepHeading {
  text-align: center;
}

.stepHeading h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.stepHeading h2::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  width: 90px;
  border-radius: 4px;
  height: 4px;
  background: #457B9D;
}

.stepHeading span {
  font-size: 19px;
  counter-reset: #333;
  font-weight: 200;
}

.stepLists {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.step {
  display: flex;
  gap: 20px;
}

.step>.number {
  width: 38px;
  height: 38px;
  background: #457B9D;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 43px;
  color: #fff;
  font-weight: 700;
  font-size: 50px;
}

.stepContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.step h2,
.step a {
  font-size: 21px;
  font-weight: 500;
}

.step a {
  text-decoration: underline;
  color: #457B9D;
}

.finalClients {
  background: #f3f3f5;
  padding: 30px 0;
}

@media screen and (max-width: 1600px) {
  .lastHero {
    height: 25vh;
  }

  .lastHeroContent {
    width: 88%;
  }
}

@media screen and (max-width: 1400px) {
  .listBoxes {
    width: 70%;
  }

  .lastHero {
    height: 25vh;
  }

  .lastHeroContent {
    width: 88%;
  }

  .finalStep>div {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .lastHero {
    height: 25vh;
  }

  .finalStep>div {
    width: 55%;
  }

  .lastHeroContent {
    width: 88%;
  }
}

@media screen and (max-width: 768px) {
  .lastHeroContent button {
    margin: 10px;
    background-color: #c64756;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 4px;
    font-size: 125%;
    font-weight: bold;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086);
  }

  .lastHeroContent button:hover {
    background-color: #369180;
  }

  .finalStep>div {
    width: 90%;
    margin: auto;
    padding: 40px 0;
  }

  .lastHero {
    height: 27vh;
    background-image: none;
    background: linear-gradient(#8a1111, #f21f1f);
  }

  .lastHeroContent button {
    margin: 10px;
    background-color: #45b29b;
  }
}

@media screen and (max-width: 600px) {
  .finalStep>div {
    width: 97%;
    margin: auto;
    padding: 40px 0;
  }

  .lastHeroContent {
    width: 98%;
  }

  .lastHeroContent h1 {
    font-size: 26px;
    margin-bottom: 5px;
  }

  .lastHeroContent h2 {
    font-size: 18px;
    font-weight: 200;
    line-height: 25px;
  }

  .lastHeroContent h2 span {
    font-weight: 600;
    margin-top: 15px;
    display: inline-block;
  }
}

@media screen and (max-width: 500px) {
  .client {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
    padding-top: 0px;
  }

  .client img {
    width: 30vw;
  }

  .lastHero {
    height: 20vh;
  }

  .lastHeroContent {
    padding: 0px;
  }

  .lastHeroContent h1 {
    font-size: 24px;
  }

  .lastHeroContent h2 {
    width: auto;
  }

  .client {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 20px 0;
    padding-top: 0px;
  }

  .client img {
    width: 140px;
  }

  .step {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .stepContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .lastHeroContent>h2 {
    font-size: 14px;
  }
}