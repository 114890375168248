#howItWorks {
  background-color: #fafafa;
  border: 1px solid #ddd;
  padding: 60px;
}

.workingWrapper {
  width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sectionHeading {
  text-align: center;
  margin-bottom: 60px;
}

.sectionHeading h2 {
  font-size: 30px;
  font-weight: 600;
  color: #1D3557;
}

.sectionHeading p {
  font-size: 22px;
}

.workingBox {
  text-align: center;
  position: relative;
}

.workingBox img {
  width: 146px;
  height: 146px;
  object-fit: scale-down;
  display: block; /* Ensures the image takes up the full width/height specified */
  margin: 0 auto; /* Centers the image if its width is less than the container */
  object-position: center;
}

.d-flex {
  display: flex!important;
}

.justify-content-center {
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

.align-items-center {
  -ms-flex-align: center!important;
  align-items: center!important;
}

.rank {
  left: 50%;
  transform: translateX(-50%);
  background: #457B9D;
  width: 50px;
  line-height: 50px;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-right: 0px;
  position: relative;
  margin-bottom: 24px;
}

.title {
  margin-top: 24px;
}

.workingBox p {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 24px;
  line-height: 25px;
}

.workingBox h3 {
  font-size: 18px;
  font-weight: 700;
  color: #1D3557;
}



@media screen and (max-width: 1200px) {
  .workingWrapper {
    width: 100%;
  }

  #howItWorks {
    padding: 60px
  }
}

@media screen and (max-width: 1000px) {
  .workingWrapper {
    width: 100%;
  }

  #howItWorks {
    padding: 60px 20px;
  }
}

@media screen and (max-width: 768px) {
  .rank {
    margin-bottom: 12px;
  }

  .workingBoxes {
    flex-wrap: wrap;
  }

  #howItWorks {
    padding: 20px 0;
  }

  .workingWrapper {
    width: 90%;
    margin: 0 auto;
  }

  .workingBox p {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 24px;
    line-height: 25px;
  }
  
  .workingBox h3 {
    font-size: 18spx;
    font-weight: 700;
    color: #1D3557;
  }

}