.listBox {
    background: #ffd9000d;
    border-radius: 12px;
    box-shadow: 0 1px 1px 0 rgba(117, 117, 117, 0.11);
    border: 1px solid #ffd700;
    padding: 20px;
    min-height: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.3s ease;
}

.listBox:hover {
    cursor: pointer !important;
    box-shadow: 0 10px 16px rgba(74, 103, 31, 0.1);
}

.pathLink {
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
}

.arrow {
    display: inline-block;
    transition: transform 0.3s ease;
}

.listBox:hover .arrow {
    transform: translateY(-4px);
}

.listBox>p {
    text-align: center;
    font-size: 14px;
}

.listFlex .listColumn:nth-child(1) {
    width: 80px;
    padding-right: 12px;
}

.listFlex .listColumn:nth-child(2) {
    width: 600px;
}

.listFlex .listColumn:nth-child(3) {
    width: 150px;
}

.listFlex .listColumn:nth-child(4) {
    width: 160px;
}


.listFlex {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.listImg {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listImg>img {
    border-radius: 10px;
    border: 1.5px solid #1D3557;
    background-color: white;
    width: 60px;
    height: 60px;
    object-fit: scale-down;
    padding: 2px;
}

.iconWrapper {
    display: flex;
    align-items: center;
    /* margin-bottom: 8px; */
}

.icon {
    width: 12px;
    height: auto;
    margin-right: 5px;
}


.iconWrapper span {
    color: rgb(82, 82, 82);
    font-size: 0.775rem;
}


.listContent {
    flex: 3;
    text-align: left;
    margin-top: 8px;
}

.listContent h2 {
    font-size: 16px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .listFlex {
        flex-wrap: wrap;
        align-items: center;
        justify-content: normal;
    }

    .listFlex .listColumn {
        width: auto;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .listImg>img {
        width: 60px;
        height: 60px;
    }

    .listContent h2,
    .iconWrapper span {
        font-size: 14px;
    }

    .icon {
        width: 10px;
        height: auto;
    }

    .listFlex .listColumn:last-child {
        margin-bottom: 10;
    }

}

@media screen and (max-width: 420px) {
    .listFlex .listColumn:last-child {
        margin-bottom: 10;
    }
}