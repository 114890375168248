.taskHero {
  background: linear-gradient(rgba(29, 53, 87, 0.94), rgba(29, 53, 87, 0.94)),
    url(../assets/offer-bg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}

.taskHeroContent {
  width: 60%;
  padding: 20px;
}

.taskHeroContent h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.taskHeroContent h2 {
  font-size: 18px;
  font-weight: 200;
  line-height: 35px;
}

.taskList {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background: #FF; */
  height: auto;
  padding-bottom: 40px;
}

.taskListBoxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: clamp(320px, 90%, 1200px);
}

.listHeading {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 15px;
}

.listHeading h2 {
  margin-bottom: 10px;
  font-size: 23px;
  font-weight: 500;
}

@media screen and (max-width: 1600px) {
  .taskHeroContent {
    width: 85%;
  }
}

@media screen and (max-width: 1400px) {
  .taskHeroContent {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) {
  .taskHeroContent {
    width: 85%;
  }
}

@media screen and (max-width: 800px) {
  .listHeading h2 {
    font-size: 17px;
    margin: 0;
  }

  .taskHeroContent {
    width: 95%;
  }

  .listHeading {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 600px) {
  .taskHero {
    background: linear-gradient(0deg, rgba(29, 53, 87, 0.83), rgba(29, 53, 87, 0.83)) no-repeat center center;
    padding: 0px;
  }

  .taskHeroContent {
    width: 100%;
  }

  .taskHeroContent h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .taskHeroContent h2 {
    font-size: 14px;
    font-weight: 200;
    line-height: 20px;
  }

  .taskHeroContent h2 span {
    font-weight: 500;
  }
}

@media screen and (max-width: 500px) {}

@media screen and (max-width: 400px) {}