.listBox {
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(117, 117, 117, 0.25);
    border-radius: 4px;
    padding: 0px 40px;
    min-height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 40px;
}

.listBox>p {
    text-align: center;
    font-size: 14px;
}

.listFlex {
    display: flex;
}

.listImg {
    width: 280px;
}

.listImg>img {
    width: 180px;
    margin-bottom: 20px;
}

.review {
    font-size: 14px;
    margin-left: 10px;
}

.review p {
    margin-left: -15px;
    margin-bottom: 5px;
}

.review img {
    width: 150px;
}

.listContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.listContent h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.listContent .ul>div {
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 28px;
}

.listContent .ul>div svg {
    fill: #45b29b;
}

.boxButton {
    z-index: 3000;
    position: absolute;
    right: 30px;
    bottom: 90px;
    background: #457B9D;
    width: 320px;
    height: 70px;
    border-radius: 5px;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.boxButton.selected {
    position: absolute;
    right: 30px;
    bottom: 90px;
    background: #5cbf2a;
    width: 320px;
    height: 70px;
    border-radius: 5px;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.innerBtn {
    position: absolute;
    right: -100px;
    box-shadow: 0 1px 6px 0 rgba(117, 117, 117, 0.25);
    display: flex;
    background: gray;
    align-items: center;
    font-size: 12px;
    padding: 8px 10px;
    background: white;
    color: #333;
}

@media screen and (max-width: 1200px) {
    .listBox {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
    }

    .listFlex {
        flex-direction: column;
        margin-bottom: 100px;
    }

    .listImg {
        width: 100%;
        display: flex;
        gap: 20px;
    }

    .review img {
        width: 110px;
    }
}

@media screen and (max-width: 1000px) {
    .listFlex {
        margin-bottom: 0px;
    }

    .boxButton {
        position: static;
        width: 95%;
        margin: auto;
    }

    .boxButton.selected {
        position: static;
        width: 95%;
        margin: auto;
    }

    .innerBtn {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .listBox {
        padding: 10px;
    }

    .listBox h2 {
        display: none;
    }

    .listBox .ul {
        font-size: 14px;
        gap: 10px;
        margin: 0 !important;
    }

    .listImg {
        flex-direction: column;
        align-items: center;
        gap: 0;
        justify-content: center;
        margin-bottom: 10px;
    }

    .review {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -10px;
    }
}

@media screen and (max-width: 600px) {
    .listBox>p {
        font-size: 12px;
        font-weight: 500;
    }

    .boxButton {
        bottom: 20%;
    }
}

@media screen and (max-width: 500px) {
    .listBox {
        padding: 10px;
    }

    .listBox h2 {
        display: none;
    }

    .listContent h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .listContent .ul>div {
        display: flex;
        align-items: center;
        gap: 10px;
        line-height: 22px;
    }

    .listContent .ul>div svg {
        fill: #45b29b;
    }
}

@media screen and (max-width: 400px) {
    .listBox .boxButton {
        position: static;
    }
}