.intro {
    width: 100% !important;
    min-height:200px;
    background-color: #1D3557;
    text-align: center;
}

.intro h1 {
    color: #fff;
    letter-spacing: 2px;
    margin-bottom: 30px;
    font-size: 60px;
    padding-top: 50px;
    text-align: center;

}

.lst-kix_r3ntrnkhio8v-7>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-7
}

ol.lst-kix_r3ntrnkhio8v-8.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-8 0
}

ol.lst-kix_r3ntrnkhio8v-2.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-2 0
}

.lst-kix_ofwh17erq4d-7>li:before {
    content: "\0025cb   "
}

.lst-kix_ofwh17erq4d-8>li:before {
    content: "\0025a0   "
}

.lst-kix_ofwh17erq4d-5>li:before {
    content: "\0025a0   "
}

.lst-kix_ofwh17erq4d-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_ilm9sjskl50q-0 {
    list-style-type: none
}

ul.lst-kix_ilm9sjskl50q-2 {
    list-style-type: none
}

.lst-kix_ilm9sjskl50q-7>li:before {
    content: "\0025cb   "
}

ul.lst-kix_ilm9sjskl50q-1 {
    list-style-type: none
}

ul.lst-kix_ilm9sjskl50q-4 {
    list-style-type: none
}

.lst-kix_ilm9sjskl50q-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_ilm9sjskl50q-3 {
    list-style-type: none
}

.lst-kix_ilm9sjskl50q-5>li:before {
    content: "\0025a0   "
}

ul.lst-kix_ilm9sjskl50q-6 {
    list-style-type: none
}

ul.lst-kix_ilm9sjskl50q-5 {
    list-style-type: none
}

ul.lst-kix_ilm9sjskl50q-8 {
    list-style-type: none
}

ul.lst-kix_ilm9sjskl50q-7 {
    list-style-type: none
}

.lst-kix_ilm9sjskl50q-3>li:before {
    content: "\0025cf   "
}

.lst-kix_ofwh17erq4d-0>li:before {
    content: "\0025cf   "
}

.lst-kix_fc42y0um5yup-4>li:before {
    content: "\0025cb   "
}

.lst-kix_fc42y0um5yup-5>li:before {
    content: "\0025a0   "
}

.lst-kix_ilm9sjskl50q-2>li:before {
    content: "\0025a0   "
}

.lst-kix_ilm9sjskl50q-4>li:before {
    content: "\0025cb   "
}

ol.lst-kix_r3ntrnkhio8v-3.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-3 0
}

.lst-kix_xg750y6piywy-0>li:before {
    content: "\0025cf   "
}

.lst-kix_fc42y0um5yup-3>li:before {
    content: "\0025cf   "
}

.lst-kix_ofwh17erq4d-1>li:before {
    content: "\0025cb   "
}

.lst-kix_ofwh17erq4d-2>li:before {
    content: "\0025a0   "
}

.lst-kix_fc42y0um5yup-7>li:before {
    content: "\0025cb   "
}

.lst-kix_xg750y6piywy-2>li:before {
    content: "\0025a0   "
}

.lst-kix_fc42y0um5yup-1>li:before {
    content: "\0025cb   "
}

.lst-kix_r3ntrnkhio8v-5>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-5
}

.lst-kix_ofwh17erq4d-3>li:before {
    content: "\0025cf   "
}

.lst-kix_ofwh17erq4d-4>li:before {
    content: "\0025cb   "
}

.lst-kix_fc42y0um5yup-8>li:before {
    content: "\0025a0   "
}

.lst-kix_xg750y6piywy-1>li:before {
    content: "\0025cb   "
}

.lst-kix_xg750y6piywy-3>li:before {
    content: "\0025cf   "
}

.lst-kix_ilm9sjskl50q-0>li:before {
    content: "\0025cf   "
}

.lst-kix_ilm9sjskl50q-1>li:before {
    content: "\0025cb   "
}

.lst-kix_a0tuorpsvj0k-8>li:before {
    content: "\0025a0   "
}

.lst-kix_fc42y0um5yup-2>li:before {
    content: "\0025a0   "
}

.lst-kix_a0tuorpsvj0k-6>li:before {
    content: "\0025cf   "
}

.lst-kix_a0tuorpsvj0k-7>li:before {
    content: "\0025cb   "
}

ul.lst-kix_xfqicxg1pm5h-0 {
    list-style-type: none
}

ul.lst-kix_xfqicxg1pm5h-1 {
    list-style-type: none
}

ul.lst-kix_xfqicxg1pm5h-2 {
    list-style-type: none
}

.lst-kix_fc42y0um5yup-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_xfqicxg1pm5h-3 {
    list-style-type: none
}

ul.lst-kix_xfqicxg1pm5h-4 {
    list-style-type: none
}

.lst-kix_a0tuorpsvj0k-3>li:before {
    content: "\0025cf   "
}

ul.lst-kix_xfqicxg1pm5h-5 {
    list-style-type: none
}

ul.lst-kix_xfqicxg1pm5h-6 {
    list-style-type: none
}

ul.lst-kix_xfqicxg1pm5h-7 {
    list-style-type: none
}

ul.lst-kix_xfqicxg1pm5h-8 {
    list-style-type: none
}

.lst-kix_a0tuorpsvj0k-4>li:before {
    content: "\0025cb   "
}

.lst-kix_a0tuorpsvj0k-5>li:before {
    content: "\0025a0   "
}

.lst-kix_a0tuorpsvj0k-2>li:before {
    content: "\0025a0   "
}

.lst-kix_a0tuorpsvj0k-0>li:before {
    content: "\0025cf   "
}

.lst-kix_a0tuorpsvj0k-1>li:before {
    content: "\0025cb   "
}

.lst-kix_xg750y6piywy-6>li:before {
    content: "\0025cf   "
}

.lst-kix_xg750y6piywy-5>li:before {
    content: "\0025a0   "
}

.lst-kix_xg750y6piywy-7>li:before {
    content: "\0025cb   "
}

.lst-kix_xg750y6piywy-4>li:before {
    content: "\0025cb   "
}

.lst-kix_xg750y6piywy-8>li:before {
    content: "\0025a0   "
}

.lst-kix_fc42y0um5yup-6>li:before {
    content: "\0025cf   "
}

.lst-kix_r3ntrnkhio8v-0>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-0, decimal) ". "
}

.lst-kix_nq5hw4cwe73g-5>li:before {
    content: "\0025a0   "
}

.lst-kix_19t0kznusche-1>li:before {
    content: "\0025cb   "
}

.lst-kix_r3ntrnkhio8v-2>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-2
}

.lst-kix_nq5hw4cwe73g-1>li:before {
    content: "\0025cb   "
}

.lst-kix_r3ntrnkhio8v-2>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-2, lower-roman) ". "
}

.lst-kix_nq5hw4cwe73g-7>li:before {
    content: "\0025cb   "
}

ul.lst-kix_53ylu0wqdm4a-4 {
    list-style-type: none
}

ul.lst-kix_53ylu0wqdm4a-3 {
    list-style-type: none
}

ul.lst-kix_53ylu0wqdm4a-2 {
    list-style-type: none
}

ul.lst-kix_53ylu0wqdm4a-1 {
    list-style-type: none
}

ul.lst-kix_53ylu0wqdm4a-0 {
    list-style-type: none
}

.lst-kix_r3ntrnkhio8v-0>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-0
}

ul.lst-kix_ofwh17erq4d-8 {
    list-style-type: none
}

ul.lst-kix_ofwh17erq4d-7 {
    list-style-type: none
}

ul.lst-kix_ofwh17erq4d-6 {
    list-style-type: none
}

ul.lst-kix_ofwh17erq4d-5 {
    list-style-type: none
}

ul.lst-kix_ofwh17erq4d-4 {
    list-style-type: none
}

ul.lst-kix_ofwh17erq4d-3 {
    list-style-type: none
}

ul.lst-kix_ofwh17erq4d-2 {
    list-style-type: none
}

ul.lst-kix_ofwh17erq4d-1 {
    list-style-type: none
}

ul.lst-kix_ofwh17erq4d-0 {
    list-style-type: none
}

.lst-kix_19t0kznusche-7>li:before {
    content: "\0025cb   "
}

.lst-kix_19t0kznusche-3>li:before {
    content: "\0025cf   "
}

.lst-kix_19t0kznusche-5>li:before {
    content: "\0025a0   "
}

.lst-kix_nq5hw4cwe73g-3>li:before {
    content: "\0025cf   "
}

ul.lst-kix_a0tuorpsvj0k-6 {
    list-style-type: none
}

ul.lst-kix_a0tuorpsvj0k-7 {
    list-style-type: none
}

ul.lst-kix_a0tuorpsvj0k-4 {
    list-style-type: none
}

ul.lst-kix_a0tuorpsvj0k-5 {
    list-style-type: none
}

ul.lst-kix_a0tuorpsvj0k-8 {
    list-style-type: none
}

.lst-kix_ilm9sjskl50q-8>li:before {
    content: "\0025a0   "
}

ul.lst-kix_a0tuorpsvj0k-2 {
    list-style-type: none
}

ul.lst-kix_a0tuorpsvj0k-3 {
    list-style-type: none
}

ul.lst-kix_a0tuorpsvj0k-0 {
    list-style-type: none
}

ul.lst-kix_a0tuorpsvj0k-1 {
    list-style-type: none
}

ol.lst-kix_r3ntrnkhio8v-6.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-6 0
}

ul.lst-kix_19t0kznusche-5 {
    list-style-type: none
}

ul.lst-kix_19t0kznusche-4 {
    list-style-type: none
}

ul.lst-kix_19t0kznusche-3 {
    list-style-type: none
}

ul.lst-kix_19t0kznusche-2 {
    list-style-type: none
}

ul.lst-kix_19t0kznusche-8 {
    list-style-type: none
}

ul.lst-kix_19t0kznusche-7 {
    list-style-type: none
}

ul.lst-kix_19t0kznusche-6 {
    list-style-type: none
}

ol.lst-kix_r3ntrnkhio8v-7.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-7 0
}

ul.lst-kix_19t0kznusche-1 {
    list-style-type: none
}

ul.lst-kix_19t0kznusche-0 {
    list-style-type: none
}

.lst-kix_r3ntrnkhio8v-1>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-1
}

ol.lst-kix_r3ntrnkhio8v-0 {
    list-style-type: none
}

ol.lst-kix_r3ntrnkhio8v-1 {
    list-style-type: none
}

ol.lst-kix_r3ntrnkhio8v-2 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-5 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-6 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-3 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-4 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-1 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-2 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-0 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-7 {
    list-style-type: none
}

ul.lst-kix_xg750y6piywy-8 {
    list-style-type: none
}

.lst-kix_xfqicxg1pm5h-1>li:before {
    content: "\0025cb   "
}

ul.lst-kix_y9u4dznllz5j-0 {
    list-style-type: none
}

.lst-kix_xfqicxg1pm5h-3>li:before {
    content: "\0025cf   "
}

ul.lst-kix_y9u4dznllz5j-2 {
    list-style-type: none
}

.lst-kix_xfqicxg1pm5h-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_y9u4dznllz5j-1 {
    list-style-type: none
}

ul.lst-kix_y9u4dznllz5j-4 {
    list-style-type: none
}

ul.lst-kix_y9u4dznllz5j-3 {
    list-style-type: none
}

ul.lst-kix_y9u4dznllz5j-6 {
    list-style-type: none
}

ul.lst-kix_y9u4dznllz5j-5 {
    list-style-type: none
}

ul.lst-kix_y9u4dznllz5j-8 {
    list-style-type: none
}

ul.lst-kix_y9u4dznllz5j-7 {
    list-style-type: none
}

.lst-kix_xfqicxg1pm5h-2>li:before {
    content: "\0025a0   "
}

.lst-kix_xfqicxg1pm5h-8>li:before {
    content: "\0025a0   "
}

ol.lst-kix_r3ntrnkhio8v-5.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-5 0
}

.lst-kix_r3ntrnkhio8v-8>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-8
}

.lst-kix_xfqicxg1pm5h-7>li:before {
    content: "\0025cb   "
}

ol.lst-kix_r3ntrnkhio8v-3 {
    list-style-type: none
}

ol.lst-kix_r3ntrnkhio8v-4 {
    list-style-type: none
}

.lst-kix_xfqicxg1pm5h-4>li:before {
    content: "\0025cb   "
}

.lst-kix_xfqicxg1pm5h-5>li:before {
    content: "\0025a0   "
}

ol.lst-kix_r3ntrnkhio8v-5 {
    list-style-type: none
}

ol.lst-kix_r3ntrnkhio8v-6 {
    list-style-type: none
}

ol.lst-kix_r3ntrnkhio8v-7 {
    list-style-type: none
}

ol.lst-kix_r3ntrnkhio8v-8 {
    list-style-type: none
}

.lst-kix_xfqicxg1pm5h-6>li:before {
    content: "\0025cf   "
}

.lst-kix_y9u4dznllz5j-5>li:before {
    content: "\0025a0   "
}

.lst-kix_y9u4dznllz5j-7>li:before {
    content: "\0025cb   "
}

.lst-kix_y9u4dznllz5j-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_fc42y0um5yup-8 {
    list-style-type: none
}

.lst-kix_y9u4dznllz5j-3>li:before {
    content: "\0025cf   "
}

.lst-kix_y9u4dznllz5j-4>li:before {
    content: "\0025cb   "
}

.lst-kix_53ylu0wqdm4a-3>li:before {
    content: "\0025cf   "
}

.lst-kix_53ylu0wqdm4a-2>li:before {
    content: "\0025a0   "
}

.lst-kix_53ylu0wqdm4a-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_fc42y0um5yup-7 {
    list-style-type: none
}

ul.lst-kix_fc42y0um5yup-6 {
    list-style-type: none
}

.lst-kix_53ylu0wqdm4a-1>li:before {
    content: "\0025cb   "
}

ul.lst-kix_fc42y0um5yup-5 {
    list-style-type: none
}

ul.lst-kix_fc42y0um5yup-4 {
    list-style-type: none
}

.lst-kix_y9u4dznllz5j-8>li:before {
    content: "\0025a0   "
}

ul.lst-kix_fc42y0um5yup-3 {
    list-style-type: none
}

ul.lst-kix_fc42y0um5yup-2 {
    list-style-type: none
}

ul.lst-kix_fc42y0um5yup-1 {
    list-style-type: none
}

ul.lst-kix_fc42y0um5yup-0 {
    list-style-type: none
}

.lst-kix_r3ntrnkhio8v-6>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-6
}

.lst-kix_s9ozsorgu23n-3>li:before {
    content: "\0025cf   "
}

.lst-kix_s9ozsorgu23n-4>li:before {
    content: "\0025cb   "
}

ul.lst-kix_53ylu0wqdm4a-8 {
    list-style-type: none
}

ul.lst-kix_53ylu0wqdm4a-7 {
    list-style-type: none
}

.lst-kix_s9ozsorgu23n-2>li:before {
    content: "\0025a0   "
}

.lst-kix_s9ozsorgu23n-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_53ylu0wqdm4a-6 {
    list-style-type: none
}

ul.lst-kix_53ylu0wqdm4a-5 {
    list-style-type: none
}

.lst-kix_s9ozsorgu23n-0>li:before {
    content: "\0025cf   "
}

.lst-kix_s9ozsorgu23n-7>li:before {
    content: "\0025cb   "
}

.lst-kix_s9ozsorgu23n-8>li:before {
    content: "\0025a0   "
}

ol.lst-kix_r3ntrnkhio8v-4.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-4 0
}

.lst-kix_s9ozsorgu23n-1>li:before {
    content: "\0025cb   "
}

.lst-kix_53ylu0wqdm4a-4>li:before {
    content: "\0025cb   "
}

.lst-kix_r3ntrnkhio8v-8>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-8, lower-roman) ". "
}

.lst-kix_53ylu0wqdm4a-5>li:before {
    content: "\0025a0   "
}

.lst-kix_r3ntrnkhio8v-7>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-7, lower-latin) ". "
}

.lst-kix_53ylu0wqdm4a-6>li:before {
    content: "\0025cf   "
}

.lst-kix_y9u4dznllz5j-2>li:before {
    content: "\0025a0   "
}

.lst-kix_53ylu0wqdm4a-8>li:before {
    content: "\0025a0   "
}

.lst-kix_r3ntrnkhio8v-4>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-4, lower-latin) ". "
}

.lst-kix_53ylu0wqdm4a-7>li:before {
    content: "\0025cb   "
}

.lst-kix_r3ntrnkhio8v-5>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-5, lower-roman) ". "
}

.lst-kix_y9u4dznllz5j-1>li:before {
    content: "\0025cb   "
}

.lst-kix_r3ntrnkhio8v-6>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-6, decimal) ". "
}

.lst-kix_s9ozsorgu23n-5>li:before {
    content: "\0025a0   "
}

.lst-kix_y9u4dznllz5j-0>li:before {
    content: "\0025cf   "
}

.lst-kix_r3ntrnkhio8v-1>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-1, lower-latin) ". "
}

.lst-kix_nq5hw4cwe73g-2>li:before {
    content: "\0025a0   "
}

.lst-kix_nq5hw4cwe73g-6>li:before {
    content: "\0025cf   "
}

.lst-kix_19t0kznusche-2>li:before {
    content: "\0025a0   "
}

.lst-kix_r3ntrnkhio8v-3>li:before {
    content: "" counter(lst-ctn-kix_r3ntrnkhio8v-3, decimal) ". "
}

.lst-kix_nq5hw4cwe73g-8>li:before {
    content: "\0025a0   "
}

.lst-kix_19t0kznusche-0>li:before {
    content: "\0025cf   "
}

.lst-kix_nq5hw4cwe73g-0>li:before {
    content: "\0025cf   "
}

ol.lst-kix_r3ntrnkhio8v-1.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-1 0
}

.lst-kix_r3ntrnkhio8v-3>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-3
}

ul.lst-kix_nq5hw4cwe73g-3 {
    list-style-type: none
}

ul.lst-kix_nq5hw4cwe73g-2 {
    list-style-type: none
}

ul.lst-kix_nq5hw4cwe73g-1 {
    list-style-type: none
}

ul.lst-kix_nq5hw4cwe73g-0 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-7 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-8 {
    list-style-type: none
}

.lst-kix_19t0kznusche-8>li:before {
    content: "\0025a0   "
}

.lst-kix_19t0kznusche-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_nq5hw4cwe73g-8 {
    list-style-type: none
}

ul.lst-kix_nq5hw4cwe73g-7 {
    list-style-type: none
}

ul.lst-kix_nq5hw4cwe73g-6 {
    list-style-type: none
}

.lst-kix_nq5hw4cwe73g-4>li:before {
    content: "\0025cb   "
}

ul.lst-kix_nq5hw4cwe73g-5 {
    list-style-type: none
}

.lst-kix_19t0kznusche-4>li:before {
    content: "\0025cb   "
}

ul.lst-kix_nq5hw4cwe73g-4 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-3 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-4 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-5 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-6 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-0 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-1 {
    list-style-type: none
}

ul.lst-kix_s9ozsorgu23n-2 {
    list-style-type: none
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ol.lst-kix_r3ntrnkhio8v-0.start {
    counter-reset: lst-ctn-kix_r3ntrnkhio8v-0 0
}

.lst-kix_r3ntrnkhio8v-4>li {
    counter-increment: lst-ctn-kix_r3ntrnkhio8v-4
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c4 {
    border-right-style: solid;
    padding: 4pt 4pt 4pt 4pt;
    border-bottom-color: #cccccc;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #cccccc;
    vertical-align: top;
    border-right-color: #cccccc;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 55.5pt;
    border-top-color: #cccccc;
    border-bottom-style: solid
}

.c5 {
    border-right-style: solid;
    padding: 4pt 4pt 4pt 4pt;
    border-bottom-color: #cccccc;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #cccccc;
    vertical-align: top;
    border-right-color: #cccccc;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 268.5pt;
    border-top-color: #cccccc;
    border-bottom-style: solid
}

.c10 {
    border-right-style: solid;
    padding: 4pt 4pt 4pt 4pt;
    border-bottom-color: #cccccc;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #cccccc;
    vertical-align: top;
    border-right-color: #cccccc;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 120.8pt;
    border-top-color: #cccccc;
    border-bottom-style: solid
}

.c23 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Verdana";
    font-style: normal
}

.c6 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 10.5pt;
    font-family: "Verdana";
    font-style: normal
}

.c0 {
    margin-left: 36pt;
    padding-top: 9pt;
    padding-left: 0pt;
    padding-bottom: 9pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c9 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: "Verdana";
    font-style: normal
}

.c1 {
    padding-top: 9pt;
    padding-bottom: 9pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c25 {
    padding-top: 12pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c7 {
    -webkit-text-decoration-skip: none;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 10.5pt;
    font-family: "Verdana"
}

.c15 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c18 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c20 {
    background-color: #ff0000;
    font-size: 9pt;
    font-family: "Verdana";
    font-weight: 400
}

.c26 {
    font-size: 9pt;
    font-family: "Verdana";
    font-weight: 400
}

.c12 {
    font-size: 9pt;
    font-family: "Verdana";
    font-weight: 700
}

.c21 {
    background-color: #ffffff;
    max-width: 100%;
    padding: 12pt 72pt 72pt 72pt
}

.c13 {
    padding: 0;
    margin: 0
}

.c16 {
    margin-left: 72pt;
    padding-left: 0pt
}

.c8 {
    height: 42pt
}

.c27 {
    background-color: #ff0000
}

.c28 {
    height: 74.2pt
}

.c17 {
    height: 11pt
}

.c24 {
    height: 63.8pt
}

.c19 {
    height: 20.2pt
}

.c14 {
    height: 85.5pt
}

.c11 {
    height: 30.8pt
}

.c22 {
    height: 117.8pt
}

.titlePolicy {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitlePolicy {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c21 li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.c21 p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.c21 h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c21 h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c21 h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c21 h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c21 h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c21 h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}