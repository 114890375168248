.aboutWrapper {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.aboutTop,
.aboutBottom {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 120px;
}

.aboutTop>.aboutLeft {
    flex: 1;
}

.aboutTop>.aboutRight {
    flex: 1;
}

.aboutBottom>.aboutLeft {
    flex: 1;
}

.aboutBottom>.aboutRight {
    flex: 1;
}


.aboutTop h2,
.aboutBottom h2 {
    font-weight: 500;
    margin-bottom: 24px;
    font-size: 30px;
    color: #1D3557;
    text-align: center;
}

.aboutTop p,
.aboutBottom p {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 32px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1280px) {
    .aboutWrapper {
        width: 95%;
        padding: 40px 30px;
    }

    .aboutLeft h2,
    .aboutRight h2 {
        font-size: 18px;
    }

    .aboutLeft p,
    .aboutRight p {
        font-size: 15px;
        line-height: 22px;
    }

    .aboutWrapper {
        gap: 20px;
    }

    .aboutTop,
    .aboutBottom {
        gap: 40px;
    }
}

@media screen and (max-width: 600px) {

    .aboutWrapper {
        width: 95%;
        padding: 10px 0;
    }

    .aboutLeft h2,
    .aboutRight h2 {
        font-size: 18px;
    }

    .aboutLeft p,
    .aboutRight p {
        font-size: 15px;
        line-height: 22px;
    }

    .aboutWrapper {
        gap: 20px;
    }

    .aboutTop .aboutRight,
    .aboutBottom .aboutLeft {
        display: none;
    }

}