.navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 18%;
    height: 40px;
}

nav {
    padding: 20px 0;
    position: sticky;
    z-index: 50000;
    top: 0;
    background: #fff;
    box-shadow: 0 8px 44px -10px rgba(0, 22, 89, .2);
    -webkit-box-shadow: 0 8px 44px -10px rgba(0, 22, 89, .2);
}

.logo {
    width: 180px;
}

.navLinks {
    display: flex;
    gap: 30px;
}

.navLinks a {
    font-weight: 600;
    color: #424141;
}

.hamburger {
    border: 1px solid #ddd;
    width: 42px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 3px;
    cursor: pointer;
    display: none;
}

.hamburger:hover {
    background: #ddd;
}

.hamburger:active {
    background: #ddd;
}

.hamburger>span {
    width: 22px;
    height: 2px;
    background: #444444;
    display: block;
    border-radius: 10px;
}

@media screen and (max-width: 1600px) {
    .navContainer {
        justify-content: space-between;
        padding: 0 5%;
    }
}

@media screen and (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .navLinks {
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        background: white;
        align-items: start;
        flex-direction: column;
        padding: 1px;
        font-size: 18px;
        max-height: 0px;
    }

    .navLinks a {
        opacity: 0;
    }

    .navLinks.open {
        display: flex;
        max-height: 180px;
        bottom: -180px;
        padding: 20px;
    }

    .navLinks.open a {
        opacity: 1;
    }
}