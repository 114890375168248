.hero {
  background: linear-gradient(0deg, rgba(29, 53, 87, 0.83), rgba(29, 53, 87, 0.83)), url(../../assets/hero.webp) no-repeat center center;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.firstHome {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
}

.heroContentContainer,
.signupFormContainer {
  flex: 1;
}

.heroContentContainer,
.signupFormContainer {
  padding-bottom: 10px;
}

.hero img {
  width: 100px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.hero_content {
  color: white;
  text-align: left;
}

.hero_content h1 {
  font-size: 42px;
  margin-top: 12px;
  font-weight: 500;
}

.heroPara {
  color: #fff;
  width: 560px;
  font-size: 13px;
  margin: -15px auto 20px;
}

.hero_content h1 span {
  font-weight: 700;
}

.hero_content h2 {
  margin-top: 24px;
  font-size: 22px;
  font-weight: 500;
}

.hero_content ul {
  margin-top: 24px;
  padding-left: 20px;
  list-style: disc;
  font-size: 17px;
  line-height: 28px;
  text-align: left;
}

.signup {
  background: #fff;
  width: 550px;
  border-radius: 16px;
  margin: 25px auto;
  text-align: left;
  padding: 20px 30px;
}

.signup>h2,
.signup>p {
  text-align: center;
}

.signup>h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.signup>p {
  font-size: 14px;
}

.gender {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 5px;
}

.gender-box {
  text-align: center;
}

.custom-radio input {
  display: none;
}

.radio-btn {
  margin: 10px;
  width: 115px;
  height: 130px;
  border: 1px solid #c7c7c7;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center center;
}

.male {
  background-image: url(../../assets/male-icon.svg);
}

.female {
  background-image: url(../../assets/female-icon.svg);
}

.radio-btn>img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: -10px;
  transform: translateY(-50%);
  border-radius: 50px;
  padding: 3px;
  transition: 0.2s;
  pointer-events: none;
  background-color: #1D3557;
  border: 1px solid #ddd;
  display: none;
  z-index: 100;
}

.radio-btn>.backOfCheck {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 20px;
  right: -9px;
  transform: translateY(-50%);
  border-radius: 50px;
  padding: 3px;
  pointer-events: none;
  background-color: white;
  border: 1px solid #ddd;
}

.radio-btn .gender-pic {
  max-width: 60px;
}

.custom-radio>input:checked+.radio-btn {
  border: 2px solid #E63946;
}

.custom-radio>input:checked+.radio-btn>img {
  display: block;
}

.custom-radio>input:checked+.radio-btn>.backOfCheck {
  display: none;
}

.dob>span {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
}

.dob-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 25px;
}

.dob select {
  padding: 17px 10px;
  width: 33%;
  border: 1px solid #dedede;
  border-radius: 4px;
  background: #fff;
}

.btn-red {
  background-color: #E63946;
  color: #fff !important;
  text-align: center;
  box-sizing: border-box;
  box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
    inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 16px;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
}

.signup-notice {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  margin: auto;
}

.hero a {
  color: #457B9D;
}

.inputBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.email {
  width: 100%;
  margin-bottom: 15px;
}

.email input {
  width: 100%;
  padding: 16px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.names {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.names input {
  width: 100%;
  padding: 16px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.selectOne {
  display: flex;
  /* border: 1px solid #ddd; */
  font-size: 12px;
  justify-content: space-around;
  overflow: hidden;
}

.selectOne p {
  padding: 0px 10px;
  width: 100%;
  padding-right: 5px;
  text-align: center;
}

.unsubscribe {
  text-align: center;
  font-size: 14px;
  color: #929292;
  font-style: italic;
}

.sbottom p {
  font-size: 10px;
  text-align: center;
  margin-bottom: 8px;
}

.signup2 h2 {
  margin-bottom: 20px;
}

.secondHome h1 {
  font-size: 30px;
  font-weight: 700;
}

@media screen and (max-width: 1100px) {
  .hero_content h1 {
    font-size: 28px;
  }

  .hero_content {
    text-align: center;
    padding: 0 15%;
  }

  .hero_content ul {
    padding-left: 10%;
  }

  .hero_content h2 {
    font-size: 16px;
  }

  .firstHome {
    flex-direction: column;
    width: 100%;
  }

  .heroContentContainer,
  .signupFormContainer {
    padding-bottom: 0px;
    width: 90%;
    flex: none;
  }
}

@media screen and (max-width: 768px) {
  .hero_content h1 {
    font-size: 28px;
  }

  .hero_content {
    padding: 0 5%;
  }

  .hero_content h2 {
    font-size: 16px;
  }

  .firstHome {
    flex-direction: column;
    width: 100%;
  }

  .heroContentContainer,
  .signupFormContainer {
    padding-bottom: 0px;
    width: 100%;
    flex: none;
  }

  .hero_content ul {
    padding-left: 8%;
  }
}

@media screen and (max-width: 550px) {

  .hero_content h1 {
    padding: 0px;
    font-size: 18px;
  }

  .hero_content h2 {
    font-size: 14px;
  }
  .signup2,
  .signup {
    width: 100%;
    padding: 12px;
    border-radius: 0px;
  }

  .heroContentContainer,
  .signupFormContainer {
    padding-bottom: 0px;
    width: 100%;
    flex: none;
  }

  .selectOne p {
    padding: 5px 10px;
    width: 100%;
    padding-right: 5px;
  }

  .selectOne div {
    width: 5%;
    display: flex;
  }

  .hero_content ul {
    display:none;
    padding-left: 2%;
  }

  .hero {
    background: linear-gradient(0deg, rgba(29, 53, 87, 0.83), rgba(29, 53, 87, 0.83)) no-repeat center center;
    background-blend-mode: normal;
    background-color: none;
  }

  .heroPara {
    color: #fff;
    width: 95%;
    font-size: 13px;
    /* margin: -15px auto 20px; */
  }
}