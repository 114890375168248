#started {
  background: linear-gradient(rgba(29, 53, 87, 0.83), rgba(29, 53, 87, 0.83)),
    url("../../assets/footer.webp");
  position: relative;
  height: 33vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  z-index: 1000;
}

#started>div h1 {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 35px;
}

#started>div a {
  background-color: #E63946;
  padding: 10px 60px;
}
/* font-family: Poppins-Medium;
font-size: 46px;
color: #FFFFFF;
letter-spacing: 0;
text-align: center; */