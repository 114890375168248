#clients {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 30px 0;
    background-color: #f3f3f5;
    border: 1px solid #ddd;
}

#clients h2 {
    font-size: 23px;
    font-weight: 500;
}

img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

.clientBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
}


.clientBox .amazon {
    width: 110px;
    height: 18px;
}

.clientBox .walmart {
    width: 150px;
}

.clientBox .paypal {
    width: 120px;
}

.clientBox .visa {
    width: 85px;
}

.clientBox .target {
    width: 85px;
    height: 48px;
}

@media screen and (max-width: 1100px) {

    .clientBox {
        flex-wrap: wrap;
    }

    #clients h2 {
        font-size: 17px;
    }

    .clientBox {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px 40px;
    }

}