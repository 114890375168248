#faqs{
    background-color: #FAFAFA;
    padding: 50px 0 30px 0;
    border: 1px solid #ddd;
}

.sectionHeading {
  text-align: center;
  margin-bottom: 60px;
}

.sectionHeading h2 {
  font-size: 30px;
  font-weight: 600;
}
.sectionHeading p {
  font-size: 22px;
}

#accordians hr{
    display: none;
}

.accordians {
    width: 40%;
    margin: 0 auto;
}

.accordian{
  max-width: 45%;
  margin: 0 auto;

  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0 4px 25px -10px rgba(0,22,89,.2);
  -webkit-box-shadow: 0 4px 25px -10px rgba(0,22,89,.2);
  -moz-box-shadow: 0 4px 25px -10px rgba(0,22,89,.2);
  font-size: 18px;
}

@media screen and (max-width: 1200px) {
  .accordian {
    max-width: 80%;
    margin: 0 auto;
  margin-bottom: 30px;

}
}
@media screen and (max-width: 600px) {
  .accordian {
    max-width: 90%;
    margin: 0 auto;
  margin-bottom: 20px;

}
}