.ftop {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120px;
}

.ftop .logo {
  width: 210px;
}

.footerCopywrite {
  height: 90px;
  background: #1D3557;
  text-align: center;
  padding: 10px 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  border-top: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notice {
  background: #1D3557;
  text-align: center;
  padding: 0px 0px 5px;
  color: #ffffff;
  font-weight: 200;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notice p {
  padding: 0 50px 0 50px;
}

@media screen and (max-width: 1280px) {
  .notice p {
    padding: 0 20px 0 20px;
  }
}

@media screen and (max-width: 600px) {
  .notice p {
    padding: 0 10px 0 10px;
  }
}