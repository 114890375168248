.offerHero {
  background: linear-gradient(rgba(29, 53, 87, 0.83), rgba(29, 53, 87, 0.83)),
    url(../assets/offer-bg.webp);
  /* display: block; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  /* overflow: auto; */

}

.offerHeroContent {
  width: 60%;
  padding: 20px;
}

.offerHeroContent h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.offerHeroContent h2 {
  font-size: 18px;
  font-weight: 200;
  line-height: 35px;
}

.offerHeroContent h2 span {
  font-weight: 600;
}

.offerList {
  background: #f3f3f5;
  width: 100vw;
  height: auto;
}

.offerListBoxes {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.transparent {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 40000;
}

.none {
  display: none !important;
}

.error {
  height: 60px;
}

.error p {
  color: #d91717;
}

.listHeading {
  font-size: 20px;
  text-align: center;
  padding-top: 30px;
}

.listHeading h2 {
  margin-bottom: 10px;
}

.listHeading h2 span {
  font-weight: 600;
}

.allButton {
  background: #1D3557;
  width: 320px;
  height: 70px;
  border-radius: 5px;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.allButton.selected {
  width: 320px;
  height: 70px;
  border-radius: 5px;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.final {
  text-align: center;
  margin-top: 20px;
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
}

.final button {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  background: #E63946;
  padding: 16px;
  border-radius: 7px;
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 0px;
}

.offerFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px 0;
  background: #313131;
  color: #fff;
  overflow-x: hidden;
}

.offerFooter>div {
  display: flex;
  gap: 60px;
}

.offerFooter>div a:hover {
  text-decoration: underline;
}

.terms::before {
  border-right: 1px solid #fff;
}

.popup {
  position: fixed;
  top: 0;
  z-index: 60000;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  width: 600px;
  padding: 17px 30px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.close {
  top: 10px;
  right: 10px;
  position: absolute;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.popup>p {
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  margin-top: 25px;
  position: relative;
}

.popup>p::before {
  content: "";
  position: absolute;
  top: -21px;
  left: -35px;
  width: 112%;
  height: 1px;
  background: #ddd;
}

.formBox {
  width: 100%;
}

.formBox h3 {
  margin-bottom: 10px;
  color: #5f5f5f;
}

.formBox h3 span {
  color: #45b29b;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.innerInputs {
  width: 100%;
  display: flex;
  gap: 11px;
}

.innerInputs input,
.innerInputs button {
  width: 100%;
}

.inputs input {
  padding: 18px 10px;
  border: 2px solid #a0a0a0;
  width: 100%;
}

.inputs button {
  width: 100%;
  padding: 18px 10px;
  color: #fff;
  border-radius: 5px;
}

.inputs .findAddress {
  background: #3c3e3d;
}

.inputs .continue {
  background: #e10805;
  padding: 18px;
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
}

@media screen and (max-width: 1600px) {
  .offerHeroContent {
    width: 85%;
  }
}

@media screen and (max-width: 1400px) {
  .offerHeroContent {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) {

  .offerHeroContent {
    width: 85%;
  }

  .offerListBoxes {
    width: 85% !important;
  }
}

@media screen and (max-width: 800px) {
  .offerHeroContent {
    width: 95%;
  }

  .offerListBoxes {
    width: 95% !important;
  }

  .popup {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .offerHero {
    background: linear-gradient(0deg, rgba(29, 53, 87, 0.83), rgba(29, 53, 87, 0.83)) no-repeat center center;
    padding: 0px;
  }

  .offerHeroContent {
    width: 100%;
  }

  .offerHeroContent h1 {
    font-size: 22px;
    margin-bottom: 5px;
  }

  .offerHeroContent h2 {
    font-size: 14px;
    font-weight: 200;
    line-height: 30px;
  }

  .offerHeroContent h2 span {
    font-weight: 500;
  }

  .offerListBoxes {
    min-width: 95%;
    margin: 0 auto 0px;
  }

  .final {
    text-align: center;
    margin-top: 20px;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: 600;
  }

  .final button {
    font-weight: 600;
  }
}

@media screen and (max-width: 500px) {
  .offerListBoxes {
    width: 90% !important;
  }

  .final button {
    font-size: 22px;
    padding: 15px;
  }
}

@media screen and (max-width: 400px) {
  .offerListBoxes {
    min-width: 100%;
  }

  .final button {
    font-size: 22px;
    padding: 15px;
  }
}